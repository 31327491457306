import {
  CashlineBusinessDetails,
  CashlineClientDetails,
  CashlineForm,
} from "redux-models/reduxStateTypes";
import {
  mapAddress,
  Merge,
  MergeFlag,
} from "helpers/mappers/mapApiToReduxCustomerDetails";

export const mapApiToReduxCashlineCustomer = ({
  resp,
  state,
  strategy = Merge.All,
}: {
  resp: any;
  state: CashlineForm;
  strategy?: MergeFlag;
}): Partial<CashlineForm> => {
  if (!resp) return state;
  const mapBusiness = (strategy & Merge.Business) === Merge.Business;
  const mapClients = (strategy & Merge.Clients) === Merge.Clients;
  return {
    businessDetails: mapBusiness
      ? mapBusinessDetails(resp)
      : state.businessDetails,
    clientDetails: mapClients ? mapClientDetails(resp) : state.clientDetails,
  };
};

export const mapBusinessDetails = (
  payload: any
): CashlineBusinessDetails | undefined => {
  return payload.generic
    ? {
        id: payload.generic.id,
        name: payload.generic.name,
        acn: payload.generic.acn,
        abn: payload.generic.abn,
        industry: payload.generic.industry,
        website: payload.generic.website,
        address: mapAddress(payload.generic.address),
        registration: payload.generic.registration,
      }
    : undefined;
};

export const mapClientDetails = (
  payload: any
): CashlineClientDetails | undefined => {
  const contact = payload?.generic?.contact;
  return contact
    ? {
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        mobilePhone: contact.mobilePhone,
      }
    : undefined;
};
