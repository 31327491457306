import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import Media from "./offcanvas-nav/Media";

const DropdownItems = styled.ul`
  transition: all 0.2s cubic-bezier(0.37, 0, 0.63, 1);
  font-size: 16px;
  position: fixed;
  z-index: 1050;
  flex-direction: column;
  max-width: 100%;
  display: none;
  background-color: #fff;
  border-radius: 8px;
  background-clip: padding-box;
  outline: 0;
  bottom: 10px;
  left: 260px;
  width: 250px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 6%);
  overflow-y: auto;

  @media ${Media.large} {
    border-radius: 0;
    width: 100vw;
    border-left: none;
    left: 0;
    height: 100vh;
    transform: translateY(100%);
    border-top: none;
    transition: all 0.2s cubic-bezier(0.37, 0, 0.63, 1);
  }

  &.profile-active {
    transform: none;
    display: flex;
    transform: translateX(0);
  }
`;

const Link = styled(NavLink)`
  padding: 1.5rem;
  display: block;
  text-decoration: none;
  color: inherit;
  width: inherit;
  height: inherit;
  font-family: inherit;
`;

const Button = styled.button`
  margin-right: 2rem;
  padding: 1.5rem;
  display: block;
  text-decoration: none;
  border: none;
  font-size: inherit;
  font-family: ${(props) => props.theme.fontStyles.subHeading};
  background-color: inherit;
  color: inherit;
  height: inherit;

  width: 100%;
  text-align: left;
`;

const Item = styled.li`
  display: block;
  justify-content: space-between;
  position: relative;
  list-style: none;
  white-space: nowrap;
  color: ${(props) =>
    props.over ? props.theme.colors.primary : props.theme.colors.colorGrayDark};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.lightPurple};
  }
`;

export { Item, Button, Link, DropdownItems };
