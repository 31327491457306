import isEmpty from "helpers/isEmpty";
import React, { useRef } from "react";
import { components } from "react-select";
import styled from "styled-components/macro";
import { DropdownButton, DropdownItem } from "../dropdowns/Dropdown.styles";
import InputContainer from "./InputContainer";
import { StyledSelect } from "./Select.styled";

type OptionData = { label: string; value: string | undefined };

const Button = styled(DropdownButton)`
  font-size: 14px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fontStyles.subHeading};
`;

const Input = ({
  autoComplete,
  ...props
}: React.ComponentProps<typeof components.Input>) => {
  return (
    <components.Input {...props} autoComplete="off" data-lpignore="true" />
  );
};

const Option = ({
  innerProps,
  innerRef,
  children,
}: {
  innerProps?: React.ComponentProps<typeof DropdownItem>;
  children?: React.ReactNode;
  innerRef?: any;
}) => {
  return (
    <>
      <DropdownItem role="option" ref={innerRef} {...innerProps}>
        <Button highlightPastel>{children}</Button>
      </DropdownItem>
    </>
  );
};

const SelectSingle = React.memo(
  ({
    placeholder,
    name,
    change,
    error,
    text,
    value = null,
    options,
    required = false,
    disabled,
    handleBlur,
    icon,
    border = false,
    isClearable = true,

    // Pageable related arguments
    filterOption = true,
    isPageable,
    setSearch,
    setPageItems,
    lastPage,
    pageItems,
    loading,
    CustomOption,
  }: {
    placeholder?: string;
    name: string;
    change: (name: string, value?: string) => any;
    error?: any;
    text?: string;
    value?: string | null;
    options: Array<OptionData>;
    required?: any;
    disabled?: any;
    handleBlur?: (arg: { target: { name: string } }) => any;
    icon?: any;
    border?: any;
    isClearable?: boolean;

    // Pageable related arguments
    filterOption?: any;
    isPageable?: any;
    setSearch?: any;
    setPageItems?: any;
    lastPage?: any;
    pageItems?: any;
    loading?: any;
    CustomOption?: any;
  }) => {
    const selectRef = useRef<any>();

    return (
      <InputContainer
        error={error}
        text={text}
        name={name}
        required={required}
        icon={icon}
      >
        <StyledSelect
          id={name}
          error={error}
          autoComplete="off"
          ref={selectRef}
          classNamePrefix="react-select"
          getOptionLabel={(option: any) => option["label"]}
          getOptionValue={(option: any) => option.value}
          name={name}
          cacheOptions
          value={options.filter((option: any) => option.value === value)}
          // @ts-expect-error
          filterOption={
            filterOption
              ? (option: any, input: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
              : false
          }
          options={options}
          isClearable={isClearable}
          backspaceRemovesValue={true}
          onInputChange={(e) => {
            if (!filterOption && isPageable) {
              setSearch(e);
              setPageItems(10);
            }
          }}
          isLoading={loading}
          onMenuScrollToBottom={() => {
            if (isPageable) {
              if (!lastPage) {
                setPageItems(pageItems + 10);
                change(name, "");
              }
            }
          }}
          onChange={(option: any) => change(name, option?.value)}
          isDisabled={disabled || options.length <= 1}
          components={
            CustomOption
              ? { Option: CustomOption, Input: Input }
              : { Option: Option, Input: Input }
          }
          placeholder={
            isEmpty(value) ? (
              placeholder
            ) : (
              <p style={{ display: "none" }}>{placeholder}</p>
            )
          }
          onBlur={() => {
            handleBlur?.({ target: { name } });
          }}
          $border={border}
        />
      </InputContainer>
    );
  }
);

export { SelectSingle };
