import styled from "styled-components/macro";

export const Dropdown = styled.div<{ focus?: any }>`
  position: absolute;
  display: ${(props) => (props.focus ? "block" : "none")};
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.colors.inputGray};
  z-index: ${(props) => props.theme.zIndex.dropdown};
  width: 100%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
`;

export const DropdownList = styled.ul`
  border-radius: 6px;
`;

export const DropdownItem = styled.li<{ highlight?: any }>`
  display: block;
  list-style: none;
  ${(props) => (props.highlight ? "background-color: #d8d9dd" : "")};

  &:last-child {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`;

export const DropdownButton = styled.button<{ highlightPastel?: any }>`
  padding: 1rem 2rem;
  background-color: inherit;
  border: none;
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.colors.colorLightGrayBlue};

  &:hover {
    background-color: ${(props) => props.theme.colors.pastelLilac};
  }
`;

export const DropdownError = styled.div`
  padding: 1rem 2rem;
  background-color: inherit;
  border: none;
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.colors.coralActive};
`;

export const Name = styled.p`
  font-size: 14px;
`;

export const Info = styled.p`
  font-size: 1.4rem;
`;

export const DotPoint = styled.span`
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 1rem 0.25rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.inputTextGray};
`;
