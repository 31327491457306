export const CONTENT_COLUMNS = {
  preview: "Preview",
  title: "Title",
  createdBy: "Created By",
  createdOn: "Created On",
  updatedBy: "Updated On",
  actions: "Actions",
} as const;

type ContentColumnKey = keyof typeof CONTENT_COLUMNS;

export const CONTENT_COLUMNS_KEYS: ContentColumnKey[] = Object.keys(
  CONTENT_COLUMNS
) as ContentColumnKey[];

export const CONTENT_COLUMNS_VALUES: typeof CONTENT_COLUMNS[ContentColumnKey][] = Object.values(
  CONTENT_COLUMNS
);

export const CONTENT_CATEGORIES = {
  productSalesEmails: "Product Sales Emails",
  thoughtLeadershipEmails: "Thought Leadership Emails",
  productSummaries: "ScotPac Product Summaries",
  blogs: "Blogs",
  industryReportsAndInsights: "Industry Reports and Insights",
  privacyAndOtherForms: "Rates, Privacy & Forms",
} as const;

type ContentCategoryKey = keyof typeof CONTENT_CATEGORIES;

export const CONTENT_CATEGORIES_KEYS: ContentCategoryKey[] = Object.keys(
  CONTENT_CATEGORIES
) as ContentCategoryKey[];

//this order is for the strapi media library folders
export const CONTENT_STRAPI_FOLDER_IDS = {
  productSalesEmails: 1,
  productSummaries: 2,
  privacyAndOtherForms: 3,
  thoughtLeadershipEmails: 4,
  industryReportsAndInsights: 5,
  blogs: 6,
} as const;

export const CONTENT_URL_PATHS = {
  productSalesEmails: "/product-sales-emails",
  thoughtLeadershipEmails: "/thought-leadership-emails",
  productSummaries: "/scotpac-product-summaries",
  blogs: "/blogs",
  industryReportsAndInsights: "/industry-reports-and-insights",
  privacyAndOtherForms: "/privacy-and-other-forms",
} as const;

type ContentUrlPathKey = keyof typeof CONTENT_URL_PATHS;

export function getStrapiFolderId(
  endPathnameRoute: string
): number | undefined {
  const contentCategoryKey = (Object.keys(
    CONTENT_URL_PATHS
  ) as ContentUrlPathKey[]).find(
    (key) => CONTENT_URL_PATHS[key] === endPathnameRoute
  );

  return contentCategoryKey
    ? CONTENT_STRAPI_FOLDER_IDS[contentCategoryKey]
    : undefined;
}

export function getContentCategoryName(
  endPathnameRoute: string
): string | undefined {
  const contentCategoryKey = (Object.keys(
    CONTENT_URL_PATHS
  ) as ContentUrlPathKey[]).find(
    (key) => CONTENT_URL_PATHS[key] === endPathnameRoute
  );

  return contentCategoryKey
    ? CONTENT_CATEGORIES[contentCategoryKey]
    : undefined;
}
