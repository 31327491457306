import isEmpty from "./isEmpty";

const moneyFormat = (money: any, minSig = 1, minInt = 1, minFrac = 0) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // minimumSignificantDigits: minSig,
    minimumIntegerDigits: minInt,
    minimumFractionDigits: minFrac,
  });

  return formatter.format(isEmpty(money) ? 0 : money);
};

export const shortMoneyFormat = (money: number) => {
  let suffix = "";
  if (money % 1000 === 0) {
    money = money / 1000;
    suffix = "K";
  }
  return moneyFormat(money) + suffix;
};

export const simpleMoneyFormat = (num: number, fractionDigits = 2) =>
  num.toFixed(fractionDigits).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatUnits = (num: number) => {
  if (num >= 1000000000) {
    return { value: `$${simpleMoneyFormat(num / 1000000000, 0)}`, suffix: "b" };
  } else if (num >= 1000000) {
    return { value: `$${simpleMoneyFormat(num / 1000000, 0)}`, suffix: "m" };
  } else if (num >= 1000) {
    return { value: `$${simpleMoneyFormat(num / 1000, 0)}`, suffix: "k" };
  } else {
    return { value: `$${simpleMoneyFormat(num, 0)}`, suffix: "" };
  }
};

export default moneyFormat;
