import React, { memo } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import isEmpty from "helpers/isEmpty";
import DropdownItem from "./DropdownItem";
import { connect } from "react-redux";

const DropdownItems = styled.ul`
  display: ${(props) => (props.showDropdown ? "block" : "none")};
  background-color: ${(props) => props.theme.colors.colorGrayf4};
  top: 100%;
  z-index: ${(props) => props.theme.zIndex.nav};
  border-radius: 6px;
  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    background-color: ${(props) => props.theme.colors.colorGrayf8};
    position: relative;
    width: 100%;
    box-shadow: none;
    padding-top: 0;
  }
`;

const Dropdown = memo(({ showDropdown, items, reference, isPublic, role }) => {
  return (
    <DropdownItems ref={reference} showDropdown={showDropdown}>
      {!isEmpty(items) &&
        items.map((item, index) => {
          if (item.link === "/fast-doc/settings" && role !== "admin") {
            return null;
          } else {
            return (
              <DropdownItem
                key={"dropdown" + item.name + index}
                {...item}
                isPublic={isPublic}
              />
            );
          }
        })}
    </DropdownItems>
  );
});

Dropdown.propTypes = {
  showDropdown: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  role: !isEmpty(state.auth.account)
    ? state.auth.account.idTokenClaims.role
    : "public",
});

export default connect(mapStateToProps, {})(Dropdown);
