import React from "react";
import PropTypes from "prop-types";
import { MdExpandMore } from "react-icons/md";
import { NavComingSoon } from "components/common/info";
import { Main, Button, MainLink } from "./ExapandMore.styled";
import { theme } from "theme";

function ExpandMore({
  isOpen,
  text,
  link,
  setReference,
  toggleShowDropdown,
  comingSoon,
}: {
  isOpen?: any;
  text?: any;
  link?: string;
  setReference?: (..._: any[]) => any;
  toggleShowDropdown?: (..._: any[]) => any;
  comingSoon?: any;
}) {
  return (
    <React.Fragment>
      {isOpen && comingSoon && <NavComingSoon />}
      {link && (
        <MainLink>
          <a href={link} target="_blank" rel="noreferrer">
            {text}
          </a>
        </MainLink>
      )}
      {!link && (
        <Button
          ref={(ref) => setReference?.(ref)}
          onClick={toggleShowDropdown}
          // @ts-expect-error
          isOpen={isOpen}
          className="inner-link"
          comingSoon={comingSoon}
        >
          <>
            <Main isOpen={isOpen} comingSoon={comingSoon}>
              <a href={link} target="_blank" rel="noreferrer">
                {text}
              </a>
            </Main>
            <MdExpandMore
              style={{
                transform: isOpen && !comingSoon ? "rotate(180deg)" : "",
                transition: "all 200ms cubic-bezier(0.25,0.25,0.47,0.455)",
              }}
              color={theme.colors.primary}
              size="2.4rem"
              title="expand_more"
            />
          </>
        </Button>
      )}
    </React.Fragment>
  );
}

ExpandMore.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  toggleShowDropdown: PropTypes.func.isRequired,
  comingSoon: PropTypes.bool,
};

export { ExpandMore };
