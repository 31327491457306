import { CashlineFinanceNeeds } from "redux-models/reduxStateTypes";

export const mapApiToCashlineFinanceNeeds = (
  payload: any
): CashlineFinanceNeeds => {
  if (!payload.blBackground) {
    return {
      financingAmount: undefined,
      financingNeed: undefined,
      annualSalesTurnover: undefined,
    };
  }

  return {
    financingAmount: payload.financingAmount,
    financingNeed: payload.blBackground.financingNeed,
    annualSalesTurnover: payload.blBackground.annualSalesTurnover,
  };
};
