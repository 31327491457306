import React from "react";
import InputContainer from "./InputContainer";
import styled from "styled-components/macro";

const Text = styled.textarea<{ error?: any }>`
  height: 17.8rem;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.colorLightGrayBlue};
  margin: 1.97rem 0 0;
  padding: 1.9rem 2.2rem;
  border-radius: 6px;
  background-color: #fff;
  resize: none;
  outline: none;
  font-size: 14px;
  font-weight: normal;
  font-family: ${(props) => props.theme.fontStyles.subHeading};
  color: ${(props) => props.theme.colors.submitBlue};

  &::placeholder {
    font-family: ${(props) => props.theme.fontStyles.subHeading};
    font-weight: normal;
    color: ${(props) => props.theme.colors.submitBlue};
  }
`;

function TextArea({
  placeholder,
  value,
  name,
  change,
  error,
  text,
  required,
  handleBlur,
  icon,
  maxLength,
}: {
  placeholder?: any;
  value?: any;
  name?: any;
  change?: React.ChangeEventHandler<HTMLTextAreaElement>;
  error?: any;
  text?: any;
  required?: any;
  handleBlur?: any;
  icon?: any;
  maxLength?: number;
}) {
  return (
    <InputContainer
      error={error}
      name={name}
      text={text}
      required={required}
      icon={icon}
    >
      <Text
        placeholder={placeholder}
        name={name}
        id={name}
        onChange={change}
        value={value}
        error={error}
        onBlur={handleBlur}
        maxLength={maxLength}
      />
    </InputContainer>
  );
}

TextArea.defaultProps = {
  required: false,
};

export { TextArea };
