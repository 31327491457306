import {
  RemoveItemAtPathPayload,
  UpdateAddressAtPathPayload,
  UpdateItemAtPathPayload,
  User,
} from "redux-models/reduxStateTypes";
import { ApiDispatch } from "./apiDispatch";
import { Dispatch } from "redux";
import {
  CALL_API,
  GET_CASHLINE_APPLICATION_FAILURE,
  GET_CASHLINE_APPLICATION_REQUEST,
  GET_CASHLINE_APPLICATION_SUCCESS,
  CASHLINE_CREATE_APPLICATION_FAILURE,
  CASHLINE_CREATE_APPLICATION_REQUEST,
  CASHLINE_CREATE_APPLICATION_SUCCESS,
  CASHLINE_CREATE_CUSTOMER_FAILURE,
  CASHLINE_CREATE_CUSTOMER_REQUEST,
  CASHLINE_CREATE_CUSTOMER_SUCCESS,
  CASHLINE_FINANCING_NEEDS_FAILURE,
  CASHLINE_FINANCING_NEEDS_REQUEST,
  CASHLINE_FINANCING_NEEDS_SUCCESS,
  CASHLINE_GET_APPLICATION_FAILURE,
  CASHLINE_GET_APPLICATION_REQUEST,
  CASHLINE_GET_APPLICATION_SUCCESS,
  CASHLINE_GET_BROKER_FAILURE,
  CASHLINE_GET_BROKER_REQUEST,
  CASHLINE_GET_BROKER_SUCCESS,
  CASHLINE_GET_CUSTOMER_DETAILS_FAILURE,
  CASHLINE_GET_CUSTOMER_DETAILS_REQUEST,
  CASHLINE_GET_CUSTOMER_DETAILS_SUCCESS,
  CASHLINE_REMOVE_ITEM_AT_PATH,
  CASHLINE_RESET_STATE,
  CASHLINE_SELECT_BROKER,
  CASHLINE_SET_ITEM_AT_PATH,
  CASHLINE_SUBMIT_APPLICATION_FAILURE,
  CASHLINE_SUBMIT_APPLICATION_REQUEST,
  CASHLINE_SUBMIT_APPLICATION_SUCCESS,
  CASHLINE_UPDATE_BDM_FAILURE,
  CASHLINE_UPDATE_BDM_REQUEST,
  CASHLINE_UPDATE_BDM_SUCCESS,
  CASHLINE_UPDATE_CUSTOMER_ADDRESS_DETAILS,
  CASHLINE_UPDATE_CUSTOMER_FAILURE,
  CASHLINE_UPDATE_CUSTOMER_REQUEST,
  CASHLINE_UPDATE_CUSTOMER_SUCCESS,
  CASHLINE_UPDATE_OFFICE_HOLDERS_FAILURE,
  CASHLINE_UPDATE_OFFICE_HOLDERS_REQUEST,
  CASHLINE_UPDATE_OFFICE_HOLDERS_SUCCESS,
} from "./types";

export const getBrokerCashline = ({ brokerId }: { brokerId: string }) => async (
  dispatch: ApiDispatch
) => {
  await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}`,
      method: "GET",
      types: [
        CASHLINE_GET_BROKER_REQUEST,
        CASHLINE_GET_BROKER_SUCCESS,
        CASHLINE_GET_BROKER_FAILURE,
      ],
    },
  });
};

export const getCashlineApplication = (
  userId: string,
  applicationId: string
) => async (dispatch: ApiDispatch) => {
  await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/cashlineapplications/${applicationId}`,
      method: "GET",
      types: [
        CASHLINE_GET_APPLICATION_REQUEST,
        CASHLINE_GET_APPLICATION_SUCCESS,
        CASHLINE_GET_APPLICATION_FAILURE,
      ],
    },
  });
};

export const getCashlineCustomerDetails = ({
  userId,
  customerId,
}: {
  userId: string;
  customerId: string;
}) => {
  return async (dispatch: ApiDispatch) => {
    dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/customers/${customerId}`,
        method: "GET",
        types: [
          CASHLINE_GET_CUSTOMER_DETAILS_REQUEST,
          CASHLINE_GET_CUSTOMER_DETAILS_SUCCESS,
          CASHLINE_GET_CUSTOMER_DETAILS_FAILURE,
        ],
      },
    });
  };
};

export const resetCashlineState = () => async (dispatch: ApiDispatch) =>
  dispatch({
    type: CASHLINE_RESET_STATE,
  });

export const selectBrokerCashline = (broker: User | null) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    type: CASHLINE_SELECT_BROKER,
    payload: broker,
  });

export const updateAddressAtPathCashline = (
  addressAndPath: UpdateAddressAtPathPayload
) => async (dispatch: Dispatch<any>) =>
  dispatch({
    type: CASHLINE_UPDATE_CUSTOMER_ADDRESS_DETAILS,
    payload: addressAndPath,
  });

export const updateItemAtPathCashline = (
  pathAndValues: UpdateItemAtPathPayload
) => async (dispatch: Dispatch<any>) =>
  dispatch({
    type: CASHLINE_SET_ITEM_AT_PATH,
    payload: pathAndValues,
  });

export const removeItemAtPathCashline = (
  payload: RemoveItemAtPathPayload
) => async (dispatch: Dispatch<any>) =>
  dispatch({
    type: CASHLINE_REMOVE_ITEM_AT_PATH,
    payload,
  });

export const submitCashlineApplication = ({
  userId,
  applicationId,
}: {
  userId: string;
  applicationId: string;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/cashlineapplications/${applicationId}/submit`,
      method: "PUT",
      types: [
        CASHLINE_SUBMIT_APPLICATION_REQUEST,
        CASHLINE_SUBMIT_APPLICATION_SUCCESS,
        CASHLINE_SUBMIT_APPLICATION_FAILURE,
      ],
    },
  });
  return { success: res?.type === CASHLINE_SUBMIT_APPLICATION_SUCCESS };
};

export const updateFinancingApplicationCashline = ({
  brokerId,
  applicationId,
  financingNeed,
  financingAmount,
  annualSalesTurnover,
}: {
  brokerId: string;
  applicationId: string;
  financingNeed: string;
  financingAmount: number;
  annualSalesTurnover: number;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}/cashlineapplications/${applicationId}/financing`,
      method: "PUT",
      body: {
        financingNeed,
        financingAmount,
        annualSalesTurnover,
      },
      types: [
        CASHLINE_FINANCING_NEEDS_REQUEST,
        CASHLINE_FINANCING_NEEDS_SUCCESS,
        CASHLINE_FINANCING_NEEDS_FAILURE,
      ],
    },
  });

  return { success: res?.type === CASHLINE_FINANCING_NEEDS_SUCCESS };
};

export const createCustomerCashline = ({
  userId,
  formData,
}: {
  userId: string;
  formData: any;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers`,
      method: "POST",
      body: formData,
      types: [
        CASHLINE_CREATE_CUSTOMER_REQUEST,
        CASHLINE_CREATE_CUSTOMER_SUCCESS,
        CASHLINE_CREATE_CUSTOMER_FAILURE,
      ],
    },
  });

  const success = res?.type === CASHLINE_CREATE_CUSTOMER_SUCCESS;
  return { success, customerId: res?.payload?.id };
};

export const updateCustomerCashline = ({
  userId,
  formData,
  customerId,
}: {
  userId: string;
  formData: any;
  customerId: string;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers/${customerId}`,
      method: "PUT",
      body: formData,
      types: [
        CASHLINE_UPDATE_CUSTOMER_REQUEST,
        CASHLINE_UPDATE_CUSTOMER_SUCCESS,
        CASHLINE_UPDATE_CUSTOMER_FAILURE,
      ],
    },
  });
  const success = res?.type === CASHLINE_UPDATE_CUSTOMER_SUCCESS;
  return { success, customerId };
};

export const updateOfficeHoldersCashline = ({
  brokerId,
  customerId,
  applicationId,
  formData,
  managerId,
}: {
  brokerId: string;
  customerId: string;
  applicationId?: string;
  formData: any;
  managerId: string | null | undefined;
}) => async (dispatch: ApiDispatch) => {
  const updateRes = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}/customers/${customerId}/officeholders`,
      method: "PUT",
      body: formData,
      types: [
        CASHLINE_UPDATE_OFFICE_HOLDERS_REQUEST,
        CASHLINE_UPDATE_OFFICE_HOLDERS_SUCCESS,
        CASHLINE_UPDATE_OFFICE_HOLDERS_FAILURE,
      ],
    },
  });

  if (updateRes?.type === CASHLINE_UPDATE_OFFICE_HOLDERS_SUCCESS) {
    if (!applicationId) {
      const createRes = await dispatch({
        [CALL_API]: {
          endpoint: `/v1/users/${brokerId}/cashlineapplications`,
          method: "POST",
          body: {
            customerId: customerId,
            authorizationCheck: true,
            managerId: managerId,
          },
          types: [
            CASHLINE_CREATE_APPLICATION_REQUEST,
            CASHLINE_CREATE_APPLICATION_SUCCESS,
            CASHLINE_CREATE_APPLICATION_FAILURE,
          ],
        },
      });
      // Update BDM id here, new a new endpoint updateApplicationBDM
      const success = createRes?.type === CASHLINE_CREATE_APPLICATION_SUCCESS;
      applicationId = createRes?.payload?.id;

      return { success, applicationId };
    } else {
      const udpateRes = await dispatch({
        [CALL_API]: {
          endpoint: `/v1/users/${brokerId}/cashlineapplications/${applicationId}/bdm`,
          method: "PUT",
          body: {
            managerId: managerId,
          },
          types: [
            CASHLINE_UPDATE_BDM_REQUEST,
            CASHLINE_UPDATE_BDM_SUCCESS,
            CASHLINE_UPDATE_BDM_FAILURE,
          ],
        },
      });
    }
  }

  return {
    success: updateRes?.type === CASHLINE_UPDATE_OFFICE_HOLDERS_SUCCESS,
    applicationId,
  };
};
