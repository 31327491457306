import styled from "styled-components/macro";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { publicNavOptions } from "components/layout/publicNavigation";
import {
  loggedInNavOptions,
  staffNavOptions,
} from "components/layout/privateNavigation";
import Offcanvas from "./offcanvas-nav/Offcanvas";
import Media from "./offcanvas-nav/Media";
import { isStaffUser } from "helpers/role";

const Container = styled.div`
  background: #fff;
  height: 100%;
  position: fixed;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #e3e4ea;
  box-shadow: 0 2px 5px rgb(0 0 0 / 6%);

  @media ${Media.large} {
    height: 70px;
    width: 100%;
  }
`;

const Navigation = ({
  width,
  active,
  handleOpen,
  isPublic,
  isMindMatrixEnabled,
  account,
}) => {
  return (
    <Container>
      <Offcanvas
        account={account}
        isPublic={isPublic}
        data={
          isPublic
            ? publicNavOptions
            : isStaffUser(account.idTokenClaims.role)
            ? staffNavOptions
            : loggedInNavOptions(account, isMindMatrixEnabled)
        }
        isActive={active}
        handleOpen={handleOpen}
      />
    </Container>
  );
};

Navigation.defaultProps = {
  isPublic: false,
};

Navigation.propTypes = {
  isPublic: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isMindMatrixEnabled: state.personalisation.isMindMatrixEnabled,
  account: state.auth.account,
});

export default connect(mapStateToProps)(Navigation);
