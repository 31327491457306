import styled from "styled-components/macro";

export default styled.a`
  padding: 15px 15px 15px 15px;
  color: inherit;
  text-decoration: inherit;
  display: inherit;

  :hover {
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.pastelLilac};
    color: ${(props) => props.theme.colors.primary};
  }
`;
