import { ApiFieldError } from "helpers/mappers";
import cloneDeep from "lodash/cloneDeep";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ApiErrorReduxState, ReduxState } from "redux-models/reduxStateTypes";
import { AllKeys } from "utils/allKeys";
import { filterDuplicates } from "utils/filterDuplicates";

const REDUCERS_WITH_ERROR_HANDLING = getReducersWithErrorHandling();

type ReducersWithErrorHandling = keyof ReduxState extends infer KEY
  ? KEY extends keyof ReduxState
    ? ReduxState[KEY] extends ApiErrorReduxState
      ? KEY
      : never
    : never
  : never;

export type FormToApiFieldMap<
  FORM_TYPE extends Record<string, any> = Record<string, any>
> = {
  // map form field name to api field names
  [FORM_FIELD_NAME in AllKeys<FORM_TYPE>]: string[];
};

export type FilterErrorArgs = {
  errors: ApiFieldError[] | undefined;
  fieldMaps?: FormToApiFieldMap[];
  filter?: (fieldError: ApiFieldError) => boolean;
};

export type UseFormErrorsArgs = {
  prefix?: string;
  fieldMap: FormToApiFieldMap;
  setFieldError: (field: string, error: string) => any;
};

export type OverallErrorArgs = {
  reducers?: ReducersWithErrorHandling[];
  filterActions?: string[];
};

/**
 * Adds http validation errors into the formik form
 */
export function useFormErrors({
  prefix,
  fieldMap,
  setFieldError,
}: UseFormErrorsArgs) {
  const fieldErrors = useRawFieldErrors();
  const fieldMapWithPrefix = addPrefixToFieldMap(fieldMap, prefix);

  useEffect(() => {
    const getFormByApiField = getFormFieldLookup(fieldMapWithPrefix);

    // reversed so that we get the first error for each field
    const reversedErrorsArray = [...fieldErrors].reverse();

    for (const apiError of reversedErrorsArray) {
      const formFields = getFormByApiField(apiError.field);

      for (const formField of formFields) {
        setFieldError(formField, apiError.message);
      }
    }
  }, [
    JSON.stringify(fieldErrors),
    JSON.stringify(fieldMapWithPrefix),
    setFieldError,
  ]);
}

/**
 * Returns raw field errors
 */
export function useRawFieldErrors() {
  return useSelector((state: ReduxState) => {
    return REDUCERS_WITH_ERROR_HANDLING.flatMap(
      (reducer) => state[reducer].error?.fieldErrors ?? []
    );
  });
}

/**
 * Returns raw field errors
 */
export function useOverallErrors({
  reducers = REDUCERS_WITH_ERROR_HANDLING,
  filterActions,
}: OverallErrorArgs = {}) {
  return useSelector((state: ReduxState) => {
    return reducers
      .flatMap((reducer) => {
        const errorState = state[reducer].error;

        if (!errorState || !errorState.overallError) {
          return [];
        }

        if (!filterActions) {
          return [errorState.overallError];
        }

        if (
          errorState.overallErrorAction &&
          filterActions.includes(errorState.overallErrorAction)
        ) {
          return [errorState.overallError];
        }

        return [];
      })

      .filter((error): error is string => Boolean(error))
      .filter(filterDuplicates);
  });
}

export function useOverallError(args: OverallErrorArgs): string | undefined {
  return useOverallErrors(args)?.[0];
}

/**
 * Returns raw field errors
 */
export function useFilteredErrors(args: Omit<FilterErrorArgs, "errors">) {
  const rawErrors = useRawFieldErrors();

  return filterErrors({
    ...args,
    errors: rawErrors,
  });
}

export function filterErrors({
  errors = [],
  fieldMaps,
  filter = (_) => true,
}: FilterErrorArgs) {
  const apiFields = fieldMaps?.flatMap(getAllApiFields);

  return (errors ?? [])
    .filter(filter)
    .filter((error) => {
      if (fieldMaps === undefined) {
        return true;
      }
      return apiFields?.includes(error.field);
    })
    .map((error) => error.message);
}

export function addPrefixToFieldMap<T extends FormToApiFieldMap>(
  fieldMap: T,
  prefix: string | undefined
): T {
  if (!prefix) {
    return fieldMap;
  }

  const newFieldMap = cloneDeep(fieldMap);

  for (const [formField, apiFields] of Object.entries(newFieldMap)) {
    const mappedApiFields = apiFields.map((apiField) => prefix + apiField);

    // @ts-expect-error
    newFieldMap[formField] = mappedApiFields;
  }

  return newFieldMap;
}

/**
 * Provide reverse lookup on our form field -> api field type
 */
function getFormFieldLookup(errorFields: FormToApiFieldMap) {
  const formFieldsByApiField: Record<string, string[]> = {};

  for (const [formField, apiFields] of Object.entries(errorFields)) {
    for (const apiField of apiFields) {
      formFieldsByApiField[apiField] = [
        ...(formFieldsByApiField[apiField] ?? []),
        formField,
      ];
    }
  }

  return (apiField: string) => {
    return formFieldsByApiField[apiField] ?? [];
  };
}

/**
 * Provide reverse lookup on our form field -> api field type
 */
function getAllApiFields(errorFields: FormToApiFieldMap): string[] {
  return Object.entries(errorFields).flatMap(([, apiFields]) => apiFields);
}

function getReducersWithErrorHandling() {
  // this is typechecked object so we don't miss any reducers with error handling
  const reducers: {
    [KEY in ReducersWithErrorHandling]: undefined;
  } = {
    businessFuel: undefined,
    assetFinance: undefined,
    cashline: undefined,
    customer: undefined,
    assetFinanceContracts: undefined,
    users: undefined,
  };

  return Object.keys(reducers) as ReducersWithErrorHandling[];
}
