import errorIcon from "assets/icons/custom-icons/icon-error.svg";
import warningIcon from "assets/icons/custom-icons/warning-icon.svg";
import { ErrorIcon } from "components/common/inputs/InputContainer";
import { FC } from "react";
import styled from "styled-components/macro";
import { ComponentProps } from "utils/component-props";

export const InputContainer = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.inputGray};
  margin-bottom: 2rem;

  input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.colorLightGrayBlue};
  }
`;

export const Label = styled.label`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.submitBlue};
  padding: 5px 0;
`;

export const Asterisk = styled.label`
  color: ${(props) => props.theme.colors.coralActive};
  padding: 2px;
`;

export const ErrorContainer = styled.div`
  color: ${(props) => props.theme.colors.colorBlack};
  border-left: 5px solid ${(props) => props.theme.colors.secondaryCoral};
  background-color: ${(props) => props.theme.colors.lightCoral};
  min-height: 60px;
  margin-top: 4px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OverallErrorContainerOuter = styled.div<{
  hasDepositWarnings?: boolean;
}>`
  color: ${(props) => props.theme.colors.colorBlack};
  background-color: ${({ theme }) => theme.colors.lightCoral};
  min-height: 60px;
  border-radius: 10px;

  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
`;

export const OverallErrorContainer: FC<
  ComponentProps<typeof OverallErrorContainerOuter>
> = ({ children, ...props }) => {
  return (
    <OverallErrorContainerOuter {...props}>
      {props.hasDepositWarnings === true ? (
        <ErrorIcon src={warningIcon} />
      ) : (
        <ErrorIcon src={errorIcon} />
      )}
      <div style={{ flex: 1 }}>{children}</div>
    </OverallErrorContainerOuter>
  );
};

export const DepositWarningContainer = styled(OverallErrorContainer)`
  background-color: ${(props) => props.theme.colors.colorCream};
`;

export const ErrorMessageContainer = styled.div``;

export const Error = styled.p<{ $red?: boolean; bold?: boolean }>`
  width: 100%;
  font-size: 1.4rem;
  color: ${({ theme, $red }) =>
    $red ? theme.colors.coralActive : theme.colors.colorBlack};
  margin-top: 4px;
  font-weight: ${(props) => (props?.bold ? "600" : "inherit")};
  padding: 5px 10px;
`;

export const Input = styled.input<{ error?: boolean }>`
  padding: 1.3rem 1.4rem;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.colorWhite};
  color: ${(props) => props.theme.colors.submitBlue};
  font-size: 14px;
  border: 1px solid
    ${(props) =>
      props?.error
        ? props.theme.colors.secondaryCoral
        : props.theme.colors.colorLightGrayBlue};
  &:focus {
    outline-color: ${(props) => props.theme.colors.pastelLilac};
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.inputTextGray};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.colorGrayf8};
    color: ${(props) => props.theme.colors.colorGrayDark};
  }
`;
export const FormSubText = styled.p`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.submitBlue}; ;
`;
