import React, { memo } from "react";
import InputContainer from "./InputContainer";
import styled, { css } from "styled-components/macro";

export type IRadioButtonOptions<
  VALUE_TYPE extends string | boolean = string
> = Array<{
  name: string;
  value: VALUE_TYPE;
}>;

const RadioButtonContainer = styled.div<{ isColumn: boolean }>`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
`;

const RadioButtonText = styled.label`
  color: ${(props) => props.theme.colors.submitBlue};
  font-size: 1.4rem;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
`;
const RadioButtonItemContainer = styled.div<{ $checked?: any }>`
  display: flex;
  align-items: center;

  &:hover {
    label {
      &:nth-child(2) {
        background-color: ${({ $checked, theme }) =>
          $checked ? theme.colors.primary : "#cbd6e2"};
      }

      &:nth-child(3) {
        color: #cbd6e2;
      }
    }
  }
`;

const StyledRadioInput = styled.label<{ $checked?: any }>`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.colorLightGrayBlue};
  transition: all ease-in 0.2s;

  ${({ $checked }) =>
    $checked &&
    css`
      background-color: ${(props) => props.theme.colors.primary};

      &:hover {
        background-color: ${(props) => props.theme.colors.primary};
      }
    `}

  &:hover {
    cursor: pointer;
  }
`;

const InvisibleRadioButton = styled.input`
  display: none;
`;

function _RadioButton<VALUE_TYPE extends string | boolean>({
  label,
  name,
  options,
  error,
  required,
  text,
  handleBlur,
  setFieldValue,
  value,
  isColumn = false,
  ...rest
}: {
  name: string;
  value?: VALUE_TYPE | null;
  label?: any;
  options?: IRadioButtonOptions<VALUE_TYPE>;
  error?: any;
  text?: any;
  handleBlur?: any;
  setFieldValue: (fieldName: string, value: VALUE_TYPE, ..._: any[]) => any;
  isColumn?: boolean;
} & Omit<React.InputHTMLAttributes<any>, "value" | "name">) {
  return (
    <InputContainer error={error} name={name} text={text} required={required}>
      <RadioButtonContainer isColumn={isColumn}>
        {options?.map((option) => {
          const isChecked = value?.toString() === option.value?.toString();
          return (
            <RadioButtonItemContainer
              $checked={isChecked}
              key={option.name}
              onClick={() => {
                setFieldValue(name, option.value);
              }}
            >
              <InvisibleRadioButton
                type="radio"
                handleBlur={handleBlur}
                {...rest}
                // @ts-expect-error
                value={option.value}
                checked={isChecked}
                onChange={() => {
                  setFieldValue(name, option.value);
                }}
                id={(name ?? "") + option.value}
              />
              <StyledRadioInput
                $checked={isChecked}
                htmlFor={name + option.name}
                role="radio"
              />
              <RadioButtonText htmlFor={name + option.name}>
                {option.name}
              </RadioButtonText>
            </RadioButtonItemContainer>
          );
        })}
      </RadioButtonContainer>
    </InputContainer>
  );
}

const RadioButton = memo(_RadioButton);

export { RadioButton };
