import React, { useEffect } from "react";
import { connect } from "react-redux";
import isEmpty from "helpers/isEmpty";
import { clearUserData, getUserPersonalisation } from "actions";
import { UploadLogoPrompt } from "components/common/info";
import { Container, Icon, Profile } from "./NavProfile.styled";
import NavProfileDropdown from "./NavProfileDropdown";
import { InviteModal } from "./invite-modal";
import Username from "./offcanvas-nav/Username";
import Avatar from "./offcanvas-nav/Avatar";
import NameTitle from "./offcanvas-nav/NameTitle";
import styled from "styled-components";
import Media from "./offcanvas-nav/Media";

const ShowLarge = styled.div`
  display: block;
  @media ${Media.large} {
    display: none;
  }
`;

const ShowSmall = styled.div`
  display: none;
  @media ${Media.large} {
    display: block;
  }
`;

class NavProfile extends React.Component {
  constructor(props) {
    super(props);

    if (props.isVerified && isEmpty(props.profilePhotoUrl)) {
      props.getUserPersonalisation(props.userId);
    }

    this.state = {
      isOpen: false,
      modalIsOpen: false,
    };
  }

  componentDidMount() {
    //Assign click handler to listen the click to close the dropdown when clicked outside
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("scroll", this.handleClickOutside);
  }

  componentWillUnmount() {
    this.props.clearUserData();

    //Remove the listener
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("scroll", this.handleClickOutside);
  }

  //If click is outside the dropdown button or display area
  //Close the dropdown
  handleClickOutside = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());

    if (
      (!path.includes(this.displayAreaRef) &&
        !path.includes(this.dropTogglerRef) &&
        !path.includes(this.dropTogglerRef2) &&
        this.state.isOpen) ||
      path.includes(this.displayAreaRef)
    ) {
      this.setState({
        isOpen: false,
      });
    }
  };

  toggleShowDropdown = (mouse) => {
    if (mouse === "out") {
      if (this.state.isOpen) {
        this.setState({
          isOpen: false,
        });
      }
    } else {
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen,
      }));
    }
  };

  setMouseOver = (mouseOver) => {
    if (mouseOver) {
      // this.toggleShowDropdown();
    } else {
      //this.toggleShowDropdown("out");
    }
  };

  toggleModal = () => {
    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }));
  };

  handleClick = (e) => {
    this.props.history.push("/profile");
  };

  render() {
    const { isOpen, modalIsOpen } = this.state;
    const {
      role,
      profilePhotoUrl,
      userId,
      loading,
      error,
      settings = false,
    } = this.props;

    const prompt = role === "broker" && !loading;
    const avatar = isEmpty(profilePhotoUrl) ? (
      <Username displayUsernameType="initals" />
    ) : (
      <Icon src={profilePhotoUrl} />
    );

    // error for the profile picture it will default to profile icon
    if (!isEmpty(error)) {
      console.log(error);
    }

    return (
      <Container className="nav-profile">
        <InviteModal isOpen={modalIsOpen} close={this.toggleModal} />
        <Profile
          onMouseOver={() => {
            if (window.innerWidth >= 1165) {
              this.setMouseOver(true);
            }
          }}
          onMouseOut={() => {
            if (window.innerWidth >= 1165) {
              this.setMouseOver(false);
            }
          }}
        >
          {settings ? (
            <>
              <ShowLarge>
                <NameTitle
                  handleClick={this.toggleShowDropdown}
                  ref={(ref) => (this.dropTogglerRef = ref)}
                >
                  <div>
                    <Username />
                  </div>
                  {role}
                </NameTitle>
              </ShowLarge>
              <ShowSmall>
                <Avatar
                  handleClick={this.toggleShowDropdown}
                  ref={(ref) => (this.dropTogglerRef2 = ref)}
                >
                  {avatar}
                </Avatar>
              </ShowSmall>
            </>
          ) : (
            <Avatar>{avatar}</Avatar>
          )}
          {settings && (
            <NavProfileDropdown
              role={role}
              showDropdown={isOpen}
              reference={(ref) => (this.displayAreaRef = ref)}
              toggleModal={this.toggleModal}
            />
          )}
        </Profile>
        {prompt && <UploadLogoPrompt userId={userId} />}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.auth.account.idTokenClaims?.role,
  userId: state.auth.account.localAccountId,
  profilePhotoUrl: state.personalisation.profilePhotoUrl,
  isVerified: state.auth.account.idTokenClaims?.isVerified,
  error: state.personalisation.error,
  loading: state.personalisation.loading,
});

export default connect(mapStateToProps, {
  getUserPersonalisation,
  clearUserData,
})(NavProfile);
