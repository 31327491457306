import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { ExpandMore } from "components/common/buttons/ExpandMore";
import Dropdown from "./Dropdown";

const BodyItem = styled.li`
  display: block;
  position: relative;
  list-style: none;
  margin: 0 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    background-color: ${(props) => props.theme.colors.colorGrayf8};
    margin: 0 2rem;
  }
`;

class NavBodyItem extends React.PureComponent {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    //Assign click handler to listen the click to close the dropdown when clicked outside
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    //Remove the listener
    document.removeEventListener("click", this.handleClickOutside);
  }

  //If click is outside the dropdown button or display area
  //Close the dropdown
  handleClickOutside = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());

    if (
      !path.includes(this.displayAreaRef) &&
      !path.includes(this.dropTogglerRef)
    ) {
      if (this.state.isOpen) {
        this.setState({
          isOpen: false,
        });
      }
    }
  };

  toggleShowDropdown = (mouse) => {
    if (mouse === "out") {
      this.setState({
        isOpen: false,
      });
    } else {
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen,
      }));
    }
  };

  setMouseOver = (mouseOver) => {
    const { comingSoon } = this.props;
    if (mouseOver && !comingSoon) {
      this.toggleShowDropdown();
    } else {
      this.toggleShowDropdown("out");
    }
  };

  render() {
    const { text, id, link, items, comingSoon, isPublic } = this.props;
    const { isOpen } = this.state;

    return (
      <BodyItem id={id}>
        <ExpandMore
          isOpen={isOpen}
          text={text}
          link={link}
          setReference={(ref) => (this.dropTogglerRef = ref)}
          toggleShowDropdown={this.toggleShowDropdown}
          comingSoon={comingSoon}
        />
        <Dropdown
          items={items}
          showDropdown={!comingSoon && isOpen}
          reference={(ref) => (this.displayAreaRef = ref)}
          isPublic={isPublic}
        />
      </BodyItem>
    );
  }
}

NavBodyItem.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  public: PropTypes.bool,
};

export default NavBodyItem;
