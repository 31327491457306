import React, { useState } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import Hamburger from "./Hamburger";
import NavigationItem from "./NavigationItem";
import NavBrand from "../NavBrand";
import NavProfile from "../NavProfile";
import Contact from "../Contact";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import logo from "assets/logos/partner-portal-logo.svg";
import Media from "./Media";
import NavigationItemMain from "./NavigationItemMain";
import { Account, ReduxState } from "redux-models/reduxStateTypes";
import { MedLinkButton } from "components/common/buttons";
import Header from "./Header";
import { isStaffUser } from "helpers/role";

const Canvas = styled.div`
  position: absolute;
  bottom: 0;
  background: #fff;
  transform: translateX(-100%);
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.offcanvas {
    top: 0;
    left: 0;
    height: 100vh;
    width: 300px;
  }
`;

const NavBrandTitle = styled(Link)`
  margin: auto;
  line-height: 1.8;
  margin: 0 3.5rem 0 1.5rem;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
`;

const Container = styled.nav`
  @media ${Media.large} {
    width: 100%;
  }
  width: 250px;
  height: 100%;
  color: ${(props) => props.theme.colors.colorGrayDark};

  .hide-text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .hamburger-button {
    display: none;
  }

  .hamburger-button,
  .offcanvas,
  .nav-title,
  .contact,
  .main-nav {
    transition: all 0.2s cubic-bezier(0.37, 0, 0.63, 1);
  }

  &.nav-active {
    .items {
      > * {
        transform: translateX(300px);
      }
    }

    .offcanvas {
      transform: none;
      visibility: visible;
      transform: translateX(0px);
    }
  }

  @media ${Media.large} {
    .hamburger-button {
      display: block;
    }

    .nav-item-main {
      display: none;
    }

    &.nav-active {
      .nav-title {
        transform: translateX(100vw);
      }

      .offcanvas {
        width: calc(100vw - 70px);
      }

      .main-nav {
        transform: translate3d(calc(100vw - 70px), 0, 0);
      }
    }
  }
`;

const NavItems = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;

  @media ${Media.large} {
    flex-direction: row;
    align-items: center;
    margin: 0 15px;
  }

  .main-nav {
    @media ${Media.large} {
      display: flex;
      align-items: center;
    }
  }
`;

const OffcanvasBody = styled.div`
  overflow: auto;
  flex: auto;

  ::-webkit-scrollbar-thumb {
    height: 5px;
    border: 8px solid transparent;
    background-clip: content-box;
    border-radius: 15px;
    background-color: #edebe9;
  }

  ::-webkit-scrollbar-button {
    background-repeat: no-repeat;
    cursor: pointer;
  }

  ::-webkit-scrollbar-corner {
    background: 0 0;
  }

  ::-webkit-scrollbar {
    width: 20px;
    height: 6px;
  }
`;

const OffcanvasFooter = styled.div`
  height: 120px;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 12px 0 12px;

  @media ${Media.small} {
    height: 85px;
  }
`;

const BrandImg = styled.img`
  width: 160px;
  float: left;

  @media ${Media.small} {
    width: 138px;
  }
`;

const HamburgerButton = styled.button`
  border: none;
  background: none;
  height: 100%;
`;

const PrimaryAction = styled.div`
  padding: 10px;
  @media ${Media.large} {
    padding: 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .apply {
    padding: 15px;
    text-align: center;

    @media ${Media.medium} {
      display: none;
    }
  }
`;

type OffcanvasProps = {
  account: Account;
  isActive: boolean;
  isPublic: boolean;
  handleOpen: () => void;
  data: [];
};

const Offcanvas = ({
  account,
  isActive,
  isPublic,
  handleOpen,
  data,
}: OffcanvasProps) => {
  const keyHandler = (event: any) => {
    if (isActive && event.keyCode === 27) handleOpen();
  };
  const role = useSelector(
    (state: ReduxState) => state.auth.account.idTokenClaims.role
  );
  useEffect(() => {
    window.document.addEventListener("keydown", keyHandler);
    return () => {
      window.document.removeEventListener("keydown", keyHandler);
    };
  });

  return (
    <Container className={`navigation ${isActive ? "nav-active" : ""}`}>
      <NavItems className="items">
        <div className="main-nav">
          <HamburgerButton className="hamburger-button" onClick={handleOpen}>
            <Hamburger aria-label="Toggle navigation" isActive={isActive} />
          </HamburgerButton>
          <NavBrandTitle className="hide-text-overflow nav-title" to="/">
            <NavBrand />
          </NavBrandTitle>
          {!isStaffUser(role) && <NavigationItemMain data={data} />}
        </div>
        {isPublic ? (
          <Contact />
        ) : (
          <PrimaryAction>
            {!isStaffUser(role) && (
              <div className="apply">
                <MedLinkButton
                  to="/apply-all"
                  $coral
                  width={13.2}
                  $radius="4px"
                >
                  Apply
                </MedLinkButton>
              </div>
            )}
            <NavProfile settings={true} />
          </PrimaryAction>
        )}
        <Canvas className="offcanvas" aria-hidden="true">
          {!isPublic && (
            <Header isDesktop={false} role={account.idTokenClaims.role} />
          )}
          <OffcanvasBody>
            <NavigationItem
              isPublic={isPublic}
              handleOpen={handleOpen}
              data={data}
            />
          </OffcanvasBody>
          <OffcanvasFooter>
            <NavBrand />
          </OffcanvasFooter>
        </Canvas>
      </NavItems>
    </Container>
  );
};

export default Offcanvas;
