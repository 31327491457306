import React from "react";
import styled from "styled-components/macro";

const Container = styled.button`
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  font-weight: 400;
  padding: 15px;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  text-decoration: none;
  text-align: left;
  outline: none;
  background: ${(props) => props.theme.colors.colorGrayf4};
  border: none;
  line-height: 1.2;

  span {
    font-size: 16px;
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.pastelLilac};
  }
`;

type NameTitleProps = {
  handleClick: () => void;
};

const NameTitle = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<NameTitleProps>
>(({ children, handleClick }, ref) => {
  return (
    <Container onClick={handleClick} ref={ref}>
      {children}
    </Container>
  );
});

export default NameTitle;
