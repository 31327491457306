import productSelectorRecommendation from "./productSelectorRecommendation";
import businessFuel from "reducers/businessFuel";
import cashline from "./cashline";
import applications from "./applications";
import assetFinance from "./assetFinance";
import assetFinanceContracts from "./assetFinanceContracts";
import auth from "./auth";
import company from "./company";
import customer from "./customer";
import mindMatrix from "./mindMatrix";
import personalisation from "./personalisation";
import promotions from "./promotions";
import quotes from "./quotes";
import users from "./users";
import productSelectorApplication from "./productSelectorApplication";
import productSelectorEnquiry from "./productSelectorEnquiry";

const rootReducer: Record<string, (state: any, action: any) => any> = {
  auth,
  company,
  applications,
  users,
  quotes,
  assetFinance,
  personalisation,
  customer,
  assetFinanceContracts,
  mindMatrix,
  promotions,
  businessFuel,
  cashline: cashline,
  productSelectorApplication,
  productSelectorEnquiry,
  productSelectorRecommendation,
};

export default rootReducer;
