import { Cashline } from "redux-models/reduxStateTypes";
import {
  CASHLINE_CREATE_APPLICATION_FAILURE,
  CASHLINE_CREATE_APPLICATION_REQUEST,
  CASHLINE_CREATE_APPLICATION_SUCCESS,
  CASHLINE_CREATE_CUSTOMER_FAILURE,
  CASHLINE_CREATE_CUSTOMER_REQUEST,
  CASHLINE_CREATE_CUSTOMER_SUCCESS,
  CASHLINE_FINANCING_NEEDS_FAILURE,
  CASHLINE_FINANCING_NEEDS_REQUEST,
  CASHLINE_FINANCING_NEEDS_SUCCESS,
  CASHLINE_GET_APPLICATION_FAILURE,
  CASHLINE_GET_APPLICATION_REQUEST,
  CASHLINE_GET_APPLICATION_SUCCESS,
  CASHLINE_GET_BROKER_FAILURE,
  CASHLINE_GET_BROKER_REQUEST,
  CASHLINE_GET_BROKER_SUCCESS,
  CASHLINE_GET_CUSTOMER_DETAILS_FAILURE,
  CASHLINE_GET_CUSTOMER_DETAILS_REQUEST,
  CASHLINE_GET_CUSTOMER_DETAILS_SUCCESS,
  CASHLINE_GET_FILES_FAILURE,
  CASHLINE_GET_FILES_REQUEST,
  CASHLINE_GET_FILES_SUCCESS,
  CASHLINE_GET_RULES_SUMMARY_FAILURE,
  CASHLINE_GET_RULES_SUMMARY_REQUEST,
  CASHLINE_GET_RULES_SUMMARY_SUCCESS,
  CASHLINE_REMOVE_ITEM_AT_PATH,
  CASHLINE_RESET_STATE,
  CASHLINE_SELECT_BROKER,
  CASHLINE_SELECT_MANAGER,
  CASHLINE_SET_ITEM_AT_PATH,
  CASHLINE_SUBMIT_APPLICATION_FAILURE,
  CASHLINE_SUBMIT_APPLICATION_REQUEST,
  CASHLINE_SUBMIT_APPLICATION_SUCCESS,
  CASHLINE_UPDATE_CUSTOMER_ADDRESS_DETAILS,
  CASHLINE_UPDATE_CUSTOMER_FAILURE,
  CASHLINE_UPDATE_CUSTOMER_REQUEST,
  CASHLINE_UPDATE_CUSTOMER_SUCCESS,
  CASHLINE_UPDATE_OFFICE_HOLDERS_FAILURE,
  CASHLINE_UPDATE_OFFICE_HOLDERS_REQUEST,
  CASHLINE_UPDATE_OFFICE_HOLDERS_SUCCESS,
  CASHLINE_UPLOAD_FILE_FAILURE,
  CASHLINE_UPLOAD_FILE_REQUEST,
  CASHLINE_UPLOAD_FILE_SUCCESS,
} from "actions/types";
import {
  removeItemAtPathRedux,
  setItemAtPathRedux,
  updateCustomerAddressDetails,
} from "helpers/data-helpers/itemAtPath";
import { loadingState, unloadingState } from "helpers/reducerHelpers";
import cloneDeep from "lodash/cloneDeep";
import { mapApiErrorToReduxState, Merge } from "helpers/mappers";
import { HttpErrorAction } from "utils/api";
import { mapApiToReduxCashlineCustomer } from "helpers/mappers/mapApiToReduxCashlineCustomer";
import { mapApiToCashlineFinanceNeeds } from "helpers/mappers/mapApiToReduxCashlineApplication";

const initialState: Cashline = {
  error: null,
  recommendation: null,
  applicationId: null,
  loading: false,
  isSubmitted: false,
  submittedDate: null,
  brokerInfo: null,
  form: {
    manager: null,
    broker: null,
    customerName: undefined,
    authorizationCheck: false,
    structure: null,
    selectedCustomerId: null,
    documents: [],
    isSubmitted: false,
    clientDetails: undefined,
    businessDetails: undefined,
    financeNeeds: undefined,
  },
};

const cashline = (
  state: Cashline = initialState,
  action: { type: string; payload: any } | HttpErrorAction
): Cashline => {
  switch (action.type) {
    case CASHLINE_GET_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_GET_APPLICATION_SUCCESS: {
      const res = action.payload;

      return {
        ...unloadingState(state, action.type),
        applicationId: res.id,
        isSubmitted: res.isSubmitted,
        submittedDate: res.submitted,
        brokerInfo: res.brokerInfo,

        form: {
          ...state.form,
          selectedCustomerId: res.customerId,
          manager: res.managerInfo,
          financeNeeds: mapApiToCashlineFinanceNeeds(res),
        },
      };
    }
    case CASHLINE_GET_APPLICATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_GET_RULES_SUMMARY_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_GET_RULES_SUMMARY_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        cashlineRules: action.payload,
      };
    case CASHLINE_GET_RULES_SUMMARY_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_GET_CUSTOMER_DETAILS_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_GET_CUSTOMER_DETAILS_SUCCESS: {
      const mappedResponse = mapApiToReduxCashlineCustomer({
        resp: action.payload,
        state: state.form,
        strategy: Merge.All,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    }
    case CASHLINE_GET_CUSTOMER_DETAILS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_CREATE_CUSTOMER_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_CREATE_CUSTOMER_SUCCESS:
      const mappedResponse = mapApiToReduxCashlineCustomer({
        resp: action.payload,
        state: state.form,
        strategy: Merge.All,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    case CASHLINE_CREATE_CUSTOMER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_UPDATE_CUSTOMER_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_UPDATE_CUSTOMER_SUCCESS: {
      const mappedResponse = mapApiToReduxCashlineCustomer({
        resp: action.payload,
        state: state.form,
        strategy: Merge.Business,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    }
    case CASHLINE_UPDATE_CUSTOMER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_UPDATE_OFFICE_HOLDERS_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_UPDATE_OFFICE_HOLDERS_SUCCESS: {
      const mappedResponse = mapApiToReduxCashlineCustomer({
        resp: action.payload,
        state: state.form,
        strategy: Merge.Clients,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    }
    case CASHLINE_UPDATE_OFFICE_HOLDERS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_CREATE_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_CREATE_APPLICATION_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        applicationId: action.payload.id,
        form: {
          ...state.form,
          selectedCustomerId: action.payload.customerId,
        },
      };
    case CASHLINE_CREATE_APPLICATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_GET_FILES_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_GET_FILES_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          documents: action.payload.documents,
        },
      };
    case CASHLINE_GET_FILES_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_UPLOAD_FILE_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_UPLOAD_FILE_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          documents: [action.payload, ...state.form.documents],
        },
      };
    case CASHLINE_UPLOAD_FILE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_SUBMIT_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_SUBMIT_APPLICATION_SUCCESS:
      const res = action.payload;

      return {
        ...unloadingState(state, action.type),
        isSubmitted: true,
        submittedDate: res.submitted,
        brokerInfo: res.brokerInfo,
      };
    case CASHLINE_SUBMIT_APPLICATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_SET_ITEM_AT_PATH:
      return setItemAtPathRedux(state, action);
    case CASHLINE_REMOVE_ITEM_AT_PATH:
      return removeItemAtPathRedux(state, action);

    case CASHLINE_UPDATE_CUSTOMER_ADDRESS_DETAILS:
      return updateCustomerAddressDetails(state, action);

    case CASHLINE_RESET_STATE:
      return initialState;

    case CASHLINE_SELECT_MANAGER:
      return {
        ...state,
        form: {
          ...state.form,
          manager: action.payload,
        },
      };

    case CASHLINE_SELECT_BROKER:
      const newState = cloneDeep(initialState);
      newState.form.broker = action.payload;
      return newState;

    case CASHLINE_GET_BROKER_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_GET_BROKER_SUCCESS:
      const user = action.payload;

      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          broker: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            companyName: user.company?.name,
            address: user.company?.address,
            role: user.role,
            status: user.status,
          },
        },
      };
    case CASHLINE_GET_BROKER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CASHLINE_FINANCING_NEEDS_REQUEST:
      return loadingState(state, action.type);
    case CASHLINE_FINANCING_NEEDS_SUCCESS: {
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          financeNeeds: mapApiToCashlineFinanceNeeds(action.payload),
        },
      };
    }
    case CASHLINE_FINANCING_NEEDS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    default:
      return state;
  }
};

export default cashline;
