import React, { useState } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import NavSubLink from "./offcanvas-nav/NavSubLink.styled";
import NavSubLinkAnchor from "./offcanvas-nav/NavSubLinkAnchor.styled";

const Link = styled(NavLink)`
  margin-right: 2rem;
  padding: 2rem 3rem;
  display: block;
  text-decoration: none;
  color: inherit;
  width: inherit;
  height: inherit;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.lightPurple};
  }
`;

const Anchor = styled.a`
  margin-right: 2rem;
  padding: 2rem 3rem;
  display: block;
  text-decoration: none;
  color: inherit;
  width: inherit;
  height: inherit;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.lightPurple};
  }
`;

const Item = styled.li`
  display: block;
  justify-content: space-between;
  position: relative;
  list-style: none;
  font-size: ${(props) => props.theme.fontSizes.nav};
  color: ${(props) =>
    props.over ? props.theme.colors.primary : props.theme.colors.colorGrayDark};
`;

const ListItem = ({ name, link, isPublic, inNewTab }) => {
  const [over, setMouseOver] = useState(false);

  const renderLink = () => {
    if (isPublic) {
      return <NavSubLinkAnchor href={link}>{name}</NavSubLinkAnchor>;
    } else if (inNewTab) {
      return (
        <NavSubLinkAnchor href={link} target="_blank" rel="noreferrer">
          {name}
        </NavSubLinkAnchor>
      );
    } else {
      return <NavSubLink to={link}>{name}</NavSubLink>;
    }
  };

  return (
    <Item
      onMouseOver={() => {
        if (window.innerWidth >= 1165) {
          setMouseOver(true);
        }
      }}
      onMouseOut={() => {
        if (window.innerWidth >= 1165) {
          setMouseOver(false);
        }
      }}
      onClick={() => setMouseOver(!over)}
      over={over}
    >
      {renderLink()}
    </Item>
  );
};

ListItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ListItem;
